import {lazy} from 'react';
import Loadable from 'ui-component/Loadable';

// MODULO APLICATIVOS
const ActionsTarefas=Loadable(lazy(() => import('views/pages/aplicativos/tarefas/Actions')));
const ActionsChat=Loadable(lazy(() => import('views/pages/aplicativos/chat/Actions')));
const ActionsCalendario=Loadable(lazy(() => import('views/pages/aplicativos/calendario/Actions')));
const ActionsKanban=Loadable(lazy(() => import('views/pages/aplicativos/kanban/Actions')));
const ActionsEmail=Loadable(lazy(() => import('views/pages/aplicativos/email/Actions')));
const ActionsTimeline=Loadable(lazy(() => import('views/pages/aplicativos/timeline/Actions')));

// MODULO BACKOFFICE
// const ActionsAcoes=Loadable(lazy(() => import('views/pages/backoffice/acoes/Actions')));
const ActionsClientesgestao=Loadable(lazy(() => import('views/pages/backoffice/clientes/Cadastrar')));
// const ActionsInteracoes=Loadable(lazy(() => import('views/pages/backoffice/interacoes/Actions')));
const ActionsModulos=Loadable(lazy(() => import('views/pages/backoffice/modulos/Actions')));
const ActionsProgramas=Loadable(lazy(() => import('views/pages/backoffice/programas/Actions')));

// MODULO CADASTROS
const ActionsJuridica=Loadable(lazy(() => import('views/pages/cadastros/juridica/Actions')));
const ActionsFisica=Loadable(lazy(() => import('views/pages/cadastros/fisica/Actions')));

// MODULO COMERCIAL
const ActionsPropostas=Loadable(lazy(() => import('views/pages/comercial/propostas/Actions')));

// MODULO CONFIGURACOES
const ActionsUsuarios=Loadable(lazy(() => import('views/pages/configuracoes/usuarios/Actions')));
const ActionsGrupos=Loadable(lazy(() => import('views/pages/configuracoes/grupos/Actions')));
const ActionsEmpresas=Loadable(lazy(() => import('views/pages/configuracoes/empresas/Actions')));
const ActionsParametros=Loadable(lazy(() => import('views/pages/configuracoes/parametros/Actions')));
const ActionsDepartamentos=Loadable(lazy(() => import('views/pages/configuracoes/departamentos/Actions')));
// const ActionsPermissoes=Loadable(lazy(() => import('views/pages/configuracoes/permissoes/Actions')));

// MODULO JURIDICO
const ActionsContratos=Loadable(lazy(() => import('views/pages/juridico/contratos/Actions')));

// MODULO PINEPR
const ActionsAtividades=Loadable(lazy(() => import('views/pages/pinepr/atividades/Actions')));
const ActionsClipping=Loadable(lazy(() => import('views/pages/pinepr/clipping/Actions')));
const ActionsOkr=Loadable(lazy(() => import('views/pages/pinepr/okr/Actions')));
const ActionsPlanejamento=Loadable(lazy(() => import('views/pages/pinepr/planejamento/Actions')));

// MODULO RH
const ActionsCargosesalarios=Loadable(lazy(() => import('views/pages/rh/cargo-salario/Actions')));
const ActionsFuncionarios=Loadable(lazy(() => import('views/pages/rh/funcionarios/Actions')));

// MODULO ADMINISTRATIVO

const ActionsServicos = Loadable(lazy(() => import('views/pages/administrativo/servicos/Actions')));

// MODULO TI
const ActionsConhecimento=Loadable(lazy(() => import('views/pages/ti/conhecimento/Cadastrar')));

// MODULO CHAMADOS
const ActionsChamados=Loadable(lazy(() => import('views/pages/chamados/chamados/Actions')));



const componentsMap = {

    'tarefas': ActionsTarefas,
    'chat': ActionsChat,
    'email': ActionsEmail,
    'calendario': ActionsCalendario,
    'kanban': ActionsKanban,
    'timeline': ActionsTimeline,

    'clientesgestao': ActionsClientesgestao,
    // 'acoes': ActionsAcoes,
    'modulos': ActionsModulos,
    'programas': ActionsProgramas,
    // 'interacoes': ActionsInteracoes,

    'usuarios': ActionsUsuarios,
    'grupos': ActionsGrupos,
    'empresas': ActionsEmpresas,
    'departamentos': ActionsDepartamentos,
    'parametros': ActionsParametros,
    // 'permissoes': ActionsPermissoes,

    'okrs': ActionsOkr,
    'clipping': ActionsClipping,
    'atividades': ActionsAtividades,
    'planejamento': ActionsPlanejamento,

    'funcionarios': ActionsFuncionarios,
    'cargosesalarios': ActionsCargosesalarios,
    
    'juridica': ActionsJuridica,
    'fisica': ActionsFisica,

    'servicos': ActionsServicos,

    'contratos': ActionsContratos,
    
    'propostas': ActionsPropostas,
    
    'conhecimento': ActionsConhecimento,

    'pagamentos': ActionsConhecimento,
    'recebimentos': ActionsConhecimento,
    'bancarias': ActionsConhecimento,
    'centro_custo': ActionsConhecimento,
    'fechamento_caixa': ActionsConhecimento,
    'fluxo_caixa': ActionsConhecimento,
    'conciliacao': ActionsConhecimento,
    'produto': ActionsConhecimento,
    'entradaprodutos': ActionsConhecimento,
    'saidaprodutos': ActionsConhecimento,
    'ramais': ActionsConhecimento,
    'filas': ActionsConhecimento,
    'gestao_filas': ActionsConhecimento,
    'ura': ActionsConhecimento,
    'especiais': ActionsConhecimento,
    'historico': ActionsConhecimento,
    /* 'chamados': ActionsConhecimento, */
    'representantes': ActionsConhecimento,
    'reservaprodutos': ActionsConhecimento,
    'baixaprodutos': ActionsConhecimento,
    'movimentacao': ActionsConhecimento,
    'vendas': ActionsConhecimento,

    'chamados': ActionsChamados,
    'chamadosnovo': ActionsChamados,
};
  
const renderImports = (path, action) => {
    const Component = componentsMap[path];
    return Component ? <Component key={`${path}/${action}`} action={action} /> : null;
};

export default renderImports;