import React from 'react';
import {TextField,FormControl,FormHelperText,Box} from '@mui/material';
import InputMask from 'react-input-mask';
import {Controller} from 'react-hook-form';

export default function InputTextField({name,control,label,rules,mask,maskChar,externalError,multiline,rows,disabled=false,type='text'}) {
  const isError=(!!externalError||!!control._formState.errors[name]);
  return (
    <FormControl fullWidth error={isError}>
      <Controller
        name={name}
        control={control}
        rules={rules}
        render={({field: {ref,...field}}) =>
          mask? (
            <InputMask {...field} disabled={disabled} mask={mask} maskChar={maskChar} inputRef={field.ref} ref={field.ref} itemRef={field.ref}>
              {() => <TextField InputLabelProps={{shrink: true}} innerRef={ref} label={label} fullWidth error={isError} disabled={disabled} />}
            </InputMask>
          ):(
            <TextField {...field} InputLabelProps={{shrink: true}} label={label} fullWidth error={isError} multiline={multiline} rows={rows} disabled={disabled} type={type} />
          )
        }
      />
      <Box sx={{minHeight: '25px'}}>
        {isError&&(
          <FormHelperText sx={{marginLeft: '0px'}}>
            {externalError?.message||control._formState.errors[name]?.message}
          </FormHelperText>
        )}
      </Box>
    </FormControl>
  );
}
