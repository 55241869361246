import {Link} from 'react-router-dom';
import {useDispatch,useSelector} from 'react-redux';

// material-ui
import {ButtonBase} from '@mui/material';

// project imports
import config from 'config';
import {MENU_OPEN} from 'store/actions';
import {useEffect} from 'react';
import LogoResoluti from 'assets/images/resolutiLogo.png';
import {useState} from 'react';
import {useContext} from 'react';
import {ConfigMenuContext} from 'contexts/ConfigMenu';

// ==============================|| MAIN LOGO ||============================== //

const LogoSection=() => {
  const defaultId=useSelector((state) => state.customization.defaultId);
  const [Logo,setLogo]=useState(LogoResoluti);
  const dispatch=useDispatch();
  const menu = useContext(ConfigMenuContext);

  useEffect(() => {
    try {
      const image=menu.response.config.logoMenu;
      setLogo(`${process.env.REACT_APP_API_KEY}/${image}`);
    } catch (error) {
      console.error('Error em carregar a logo',error);
    }
  },[])

  return (
    <ButtonBase style={{width: '100%',height: '50%',transform: 'scale:(0.5)'}} disableRipple onClick={() => dispatch({type: MENU_OPEN,id: defaultId})} component={Link} to={config.defaultPath}>
      <img src={Logo} alt="Logo" width="50" />
    </ButtonBase>
  );
};

export default LogoSection;
