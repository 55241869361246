import {Box,Card,CardHeader,LinearProgress,Modal,Typography,linearProgressClasses,styled,useMediaQuery,useTheme,Tooltip} from "@mui/material";
import axios from "axios";
import {useDateFilterConfig} from "contexts/DateFilterConfig";
import {useState} from "react";
import {useEffect} from "react";
import Carousel from "react-material-ui-carousel";
import {useLocation} from "react-router";
import Div from "ui-component/layouts/components/Div";

const OKRs=() => {
    const lessThan1930=useMediaQuery('(max-width:1930px)');
    const lessThan1650=useMediaQuery('(max-width:1650px)');
    const lessThan1532=useMediaQuery('(max-width:1532px)');
    const lessThan1400=useMediaQuery('(max-width:1400px)');
    const lessThan1370=useMediaQuery('(max-width:1370px)');
    const lessThan1335=useMediaQuery('(max-width:1335px)');
    const lessThan1300=useMediaQuery('(max-width:1300px)');
    const lessThan1270=useMediaQuery('(max-width:1270px)');
    const lessThan1235=useMediaQuery('(max-width:1235px)');
    const theme=useTheme();
    const {DateFilterConfig}=useDateFilterConfig();
    const [loading,setLoading]=useState(true);
    const [okrs,setOkrs]=useState([]);
    const pathname=useLocation().pathname;
    const md=useMediaQuery(theme.breakpoints.up('md'));
    const isMobile=useMediaQuery(theme.breakpoints.down('sm'));
    const isTablet=useMediaQuery(theme.breakpoints.down('md'));
    const chunkSize=isTablet? (isMobile? 1:2):3;

    function groupIntoChunks(array,chunkSize) {
        const output=[];
        let currentChunk=[];

        array.forEach((item,index) => {
            currentChunk.push(item);

            if((index+1)%chunkSize===0||index===array.length-1) {
                output.push(currentChunk);
                currentChunk=[];
            }
        });

        return output;
    }


    useEffect(() => {
        fetchOkrs();
    },[
        DateFilterConfig,
        DateFilterConfig.startDate,
        DateFilterConfig.endDate,
    ]);

    useEffect(() => {
        fetchOkrs();
    },[])

    const fetchOkrs=async () => {
        try {
            setLoading(true);
            const data_inicial=JSON.stringify(DateFilterConfig.startDate).split('T')[0].replace('"','');
            const data_final=JSON.stringify(DateFilterConfig.endDate).split('T')[0].replace('"','');
            const response=await axios.get(`${process.env.REACT_APP_BACKEND_URL}/pinepr/graficos_okrs/listar?where[contratos_id]=1&whereData[data][entre]=${data_inicial},${data_final}`);
            // const responsePlanejamento=await axios.get(`${process.env.REACT_APP_BACKEND_URL}/pinepr/planejamento/listar?where[contratos_id]=1&whereData[data_inicio][maiori]=${data_inicial}&whereData[data_fim][menori]=${data_final}`,{
            //     headers: {
            //         'Content-Type': 'application/json',
            //         'Authorization': `Bearer ${localStorage.getItem('accessToken')}`
            //     }
            // });
            // const planejamento=responsePlanejamento.data.data.objetos[0];
            // const objetivos=[];
            // planejamento.pinepr_planejamento_objetivos.forEach((item) => {
            //     const objObjetivo={};
            //     objObjetivo.descricao=item?.descricao;
            //     objObjetivo.tipo_pinepr_objetivo=item?.tipo_pinepr_objetivo?.tipo;
            //     const objPublicos=[]
            //     item.pinepr_planejamento_krs.forEach((kr) => {
            //         const objKr=[];
            //         const okrsMap=Object.keys(response.data.data.objetos[0]);
            //         const verificaSePublicoCadastrado=objPublicos.find((publico) => publico.pinepr_publico_alvo===kr?.pinepr_publico_alvo?.publico);

            //         okrsMap.forEach((item) => {
            //             const numero=item.replace('kr','')
            //             if(Number(kr.numero)===Number(numero)) {
            //                 objKr.push({
            //                     ...response.data.data.objetos[0][item],
            //                     numero: Number(numero)
            //                 });
            //             }
            //         });

            //         if(verificaSePublicoCadastrado) {
            //             verificaSePublicoCadastrado.kr.push(...objKr);
            //         } else {
            //             objPublicos.push({
            //                 pinepr_publico_alvo: kr?.pinepr_publico_alvo?.publico,
            //                 kr: objKr
            //             });
            //         }
            //     })
            //     objObjetivo.publicos=objPublicos;
            //     objetivos.push(objObjetivo);
            // });
            // console.log(objetivos);
            const okrsMap=Object.keys(response.data.data.objetos[0]);
            const okrsResult=okrsMap.map((item) => {
                const numero=item.replace('kr','')
                return {
                    ...response.data.data.objetos[0][item],
                    numero: Number(numero)
                };
            });
            setOkrs(okrsResult);
        } catch(error) {
            console.error(error);
        } finally {
            setLoading(false);
        }
    }

    const width=() => {
        let sumWidth=0;
        if(okrs.length<=3) {
            sumWidth=8.6;
        }
        if(lessThan1235) {
            return `${30+sumWidth}%`
        } else if(lessThan1270) {
            return `${30+sumWidth}%`
        } else if(lessThan1300) {
            return `${30+sumWidth}%`
        } else if(lessThan1335) {
            return `${30+sumWidth}%`
        } else if(lessThan1370) {
            return `${30+sumWidth}%`
        } else if(lessThan1400) {
            return `${22.1+sumWidth}%`
        } else if(lessThan1532) {
            return `${22.4+sumWidth}%`
        } else if(lessThan1650) {
            return `${22.5+sumWidth}%`
        } else if(lessThan1930) {
            return `${23+sumWidth}%`
        } else {
            return `${21+sumWidth}%`
        }
    }

    const BorderLinearProgress=styled(LinearProgress)(({theme}) => ({
        height: 30,
        borderRadius: 5,
        [`&.${linearProgressClasses.colorPrimary}`]: {
            backgroundColor: theme.quaternaryPinePr,
        },
        [`& .${linearProgressClasses.bar}`]: {
            borderRadius: 5,
            backgroundColor: theme.tertiaryPinePr,
        },
    }));

    if(loading) return (
        <Modal open={loading}>
            <Box sx={{display: 'flex',justifyContent: 'center',alignItems: 'center',height: '100vh'}}>
                <div className="loader"></div>
            </Box>
        </Modal>
    );

    return (
        <Div sx={{padding: 2}}>
            <Div>
                <Typography variant="h2" color={theme.primaryPinePr} gutterBottom>
                    OKRs - {pathname.split('/')[2].toUpperCase()}
                </Typography>
            </Div>
            <Div sx={{display: 'flex',gap: 5,flexWrap: 'wrap',marginTop: '35px'}}>
                {
                    !md? (
                        <Carousel navButtonsAlwaysInvisible={true} sx={{width: '100%'}} >
                            {groupIntoChunks(okrs,chunkSize).map((group,groupIndex) => (
                                <Div key={groupIndex} sx={{display: 'flex',gap: 1,width: '100%'}}>
                                    {
                                        group.map((item,index) => (
                                            <Div key={`KR ${index+1}`} sx={{width: `${100/chunkSize}%`}} >
                                                <Div sx={{height: '120px',bgcolor: theme.secondaryPinePr,marginBottom: '-20px',borderRadius: '10px',display: 'flex',alignItems: 'center',justifyContent: 'center',boxShadow: `0px 0px 9px -1px ${theme.secondaryPinePr}`}}>
                                                    <Div sx={{display: 'flex',width: '100%',gap: 2,justifyContent: 'center',alignItems: 'center',marginBottom: '20px',flexDirection: 'column',textAlign: 'center'}}>
                                                        <Typography variant="h4" color={'white'} gutterBottom>
                                                            Tipo de Objetivo: {item.pineprTipoObjetivo||'Sem tipo de objetivo'}
                                                        </Typography>
                                                        <Typography variant="h4" color={'white'} gutterBottom>
                                                            Público Alvo: {item.pineprPublicoAlvo||'Sem público alvo'}
                                                        </Typography>
                                                    </Div>
                                                </Div>
                                                <Card sx={{height: '290px',paddingBottom: '25px',border: `2px solid ${theme.secondaryPinePr}`,borderRadius: '0px 0px 15px 15px',boxShadow: `0px 0px 9px -1px ${theme.secondaryPinePr}`}}>
                                                    <Tooltip placement="top">
                                                        <CardHeader
                                                            sx={{textAlign: 'center',color: theme.primaryPinePr,marginBottom: '-20px',marginTop: '10px','[class*="MuiTypography-root"]': {fontWeight: '600'}}}
                                                            title={`KR ${item.numero}`}
                                                            subheaderTypographyProps={{color: theme.primaryPinePr}}
                                                            subheader={`${item.nome.length>40? `${item.nome.substring(0,40)}...`:item.nome}`}
                                                        />
                                                    </Tooltip>
                                                    <Div sx={{width: '100%',marginTop: '20px',display: 'flex',flexDirection: 'column'}}>
                                                        <Div sx={{display: 'flex',justifyContent: 'space-evenly',width: '100%'}}>
                                                            <Div sx={{display: 'flex',flexDirection: 'column',gap: 2,textAlign: 'center'}}>
                                                                <Typography variant="body" sx={{fontSize: '15px',color: theme.primaryPinePr}}>KR1 Atingido</Typography>
                                                                <Typography variant="body" sx={{fontSize: '20px',color: theme.primaryPinePr,fontWeight: '600'}}>{item.atingido}</Typography>
                                                            </Div>
                                                            <Div sx={{display: 'flex',flexDirection: 'column',gap: 2}}>
                                                                <Typography variant="body" sx={{fontSize: '15px',color: theme.primaryPinePr}}>|</Typography>
                                                            </Div>
                                                            <Div sx={{display: 'flex',flexDirection: 'column',gap: 2,textAlign: 'center'}}>
                                                                <Typography variant="body" sx={{fontSize: '15px',color: theme.primaryPinePr}}>Meta KR1</Typography>
                                                                <Typography variant="body" sx={{fontSize: '20px',color: theme.primaryPinePr,fontWeight: '600'}}>{item.meta}</Typography>
                                                            </Div>
                                                        </Div>
                                                    </Div>
                                                    <Div sx={{display: 'flex',gap: 2,justifyContent: 'center',marginTop: '20px'}}>
                                                        <Div sx={{display: 'flex',gap: 1}}>
                                                            <Div sx={{height: '15px',bgcolor: theme.tertiaryPinePr,width: '20px'}}></Div>
                                                            <Typography variant="body" sx={{fontSize: '15px',color: theme.primaryPinePr}}>KR1 Atingido</Typography>
                                                        </Div>
                                                        <Div sx={{display: 'flex',gap: 1}}>
                                                            <Div sx={{height: '15px',bgcolor: theme.quaternaryPinePr,width: '20px'}}></Div>
                                                            <Typography variant="body" sx={{fontSize: '15px',color: theme.primaryPinePr}}>Meta KR1</Typography>
                                                        </Div>
                                                    </Div>
                                                    <BorderLinearProgress sx={{margin: '20px',marginBottom: '0px'}} variant="determinate" value={item.porcentagem} />
                                                </Card>
                                            </Div>
                                        ))}
                                </Div>
                            ))
                            }
                        </Carousel>
                    ):(
                        <>
                            {
                                okrs.map((item,index) => (
                                    <Div key={`KR ${index+1}`} sx={{width: width()}} >
                                        <Div sx={{height: '120px',bgcolor: theme.secondaryPinePr,marginBottom: '-20px',borderRadius: '10px',display: 'flex',alignItems: 'center',justifyContent: 'center',boxShadow: `0px 0px 9px -1px ${theme.secondaryPinePr}`}}>
                                            <Div sx={{display: 'flex',width: '100%',gap: 2,justifyContent: 'center',alignItems: 'center',marginBottom: '20px',flexDirection: 'column',textAlign: 'center'}}>
                                                <Typography variant="h4" color={'white'} gutterBottom>
                                                    Tipo de Objetivo: {item.pineprTipoObjetivo||'Sem tipo de objetivo'}
                                                </Typography>
                                                <Typography variant="h4" color={'white'} gutterBottom>
                                                    Público Alvo: {item.pineprPublicoAlvo||'Sem público alvo'}
                                                </Typography>
                                            </Div>
                                        </Div>
                                        <Card sx={{height: '290px',paddingBottom: '25px',border: `2px solid ${theme.secondaryPinePr}`,borderRadius: '0px 0px 15px 15px',boxShadow: `0px 0px 9px -1px ${theme.secondaryPinePr}`}}>
                                            <Tooltip title={item.nome} placement="top">
                                                <CardHeader
                                                    sx={{textAlign: 'center',color: theme.primaryPinePr,marginBottom: '-20px',marginTop: '10px','[class*="MuiTypography-root"]': {fontWeight: '600'},'[class*="MuiCardHeader-content"]': {height: '50px'}}}
                                                    title={`KR ${item.numero}`}
                                                    subheaderTypographyProps={{color: theme.primaryPinePr}}
                                                    subheader={`${item.nome.length>40? `${item.nome.substring(0,40)}...`:item.nome}`}
                                                />
                                            </Tooltip>
                                            <Div sx={{width: '100%',marginTop: '20px',display: 'flex',flexDirection: 'column'}}>
                                                <Div sx={{display: 'flex',justifyContent: 'space-evenly',width: '100%'}}>
                                                    <Div sx={{display: 'flex',flexDirection: 'column',gap: 2,textAlign: 'center'}}>
                                                        <Typography variant="body" sx={{fontSize: '15px',color: theme.primaryPinePr}}>KR1 Atingido</Typography>
                                                        <Typography variant="body" sx={{fontSize: '20px',color: theme.primaryPinePr,fontWeight: '600'}}>{item.atingido}</Typography>
                                                    </Div>
                                                    <Div sx={{display: 'flex',flexDirection: 'column',gap: 2}}>
                                                        <Typography variant="body" sx={{fontSize: '15px',color: theme.primaryPinePr}}>|</Typography>
                                                    </Div>
                                                    <Div sx={{display: 'flex',flexDirection: 'column',gap: 2,textAlign: 'center'}}>
                                                        <Typography variant="body" sx={{fontSize: '15px',color: theme.primaryPinePr}}>Meta KR1</Typography>
                                                        <Typography variant="body" sx={{fontSize: '20px',color: theme.primaryPinePr,fontWeight: '600'}}>{item.meta}</Typography>
                                                    </Div>
                                                </Div>
                                            </Div>
                                            <Div sx={{display: 'flex',gap: 2,justifyContent: 'center',marginTop: '20px'}}>
                                                <Div sx={{display: 'flex',gap: 1}}>
                                                    <Div sx={{height: '15px',bgcolor: theme.tertiaryPinePr,width: '20px'}}></Div>
                                                    <Typography variant="body" sx={{fontSize: '15px',color: theme.primaryPinePr}}>KR1 Atingido</Typography>
                                                </Div>
                                                <Div sx={{display: 'flex',gap: 1}}>
                                                    <Div sx={{height: '15px',bgcolor: theme.quaternaryPinePr,width: '20px'}}></Div>
                                                    <Typography variant="body" sx={{fontSize: '15px',color: theme.primaryPinePr}}>Meta KR1</Typography>
                                                </Div>
                                            </Div>
                                            <BorderLinearProgress sx={{margin: '20px',marginBottom: '0px'}} variant="determinate" value={item.porcentagem} />
                                        </Card>
                                    </Div>
                                ))
                            }
                        </>
                    )
                }
            </Div>
        </Div>
    )
}

export default OKRs;