import {useState,useRef,useEffect} from 'react';

import {useNavigate} from 'react-router-dom';
import {useSelector} from 'react-redux';

// material-ui
import {
  Avatar,
  Box,
  // Card,
  // CardContent,
  Chip,
  ClickAwayListener,
  // Divider,
  // Grid,
  // InputAdornment,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  // OutlinedInput,
  Paper,
  Popper,
  // Stack,
  // Switch,
  Typography
} from '@mui/material';

// third-party
// import PerfectScrollbar from 'react-perfect-scrollbar';

// project imports
import MainCard from 'ui-component/cards/MainCard';
import Transitions from 'ui-component/extended/Transitions';

// assets
import {IconLogout
  // ,IconSearch
  ,IconSettings
  // ,IconUser
} from '@tabler/icons';
import {useTheme} from '@mui/system';
import axios from 'axios';
import {AccountCircle} from '@mui/icons-material';

// ==============================|| PROFILE MENU ||============================== //

const ProfileSection=() => {
  const theme=useTheme();
  const customization=useSelector((state) => state.customization);
  const navigate=useNavigate();

  // const [sdm,setSdm]=useState(true);
  // const [value,setValue]=useState('');
  // const [notification,setNotification]=useState(false);
  const [selectedIndex
    // ,setSelectedIndex
  ]=useState(-1);
  const [open,setOpen]=useState(false);
  /**
   * anchorRef is used on different componets and specifying one type leads to other components throwing an error
   * */
  const anchorRef=useRef(null);

  const handleClose=(event) => {
    if(anchorRef.current&&anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  // const handleListItemClick=(event,index,route='') => {
  //   setSelectedIndex(index);
  //   handleClose(event);

  //   if(route&&route!=='') {
  //     navigate(route);
  //   }
  // };
  const handleToggle=() => {
    setOpen((prevOpen) => !prevOpen);
  };

  const prevOpen=useRef(open);
  useEffect(() => {
    if(prevOpen.current===true&&open===false) {
      anchorRef.current.focus();
    }

    prevOpen.current=open;
  },[open]);

  return (
    <>
      <Chip
        sx={{
          height: '48px',
          alignItems: 'center',
          borderRadius: '27px',
          transition: 'all .2s ease-in-out',
          borderColor: 'white',
          background: theme.button,
          '& svg': {stroke: theme.palette.grey[700],},
          '&[aria-controls="menu-list-grow"], &:hover': {
            borderColor: theme.palette.resoOrange,
            backgroundColor: `${theme.buttonHover} !important`,
            color: theme.palette.primary.light,
            '& svg': {
              stroke: 'white'
            }
          },
          '& .MuiChip-label': {
            lineHeight: 0
          }
        }}
        icon={
          <Avatar
            src={<AccountCircle />}
            sx={{
              ...theme.typography.mediumAvatar,
              margin: '8px 0 8px 8px !important',
              cursor: 'pointer'
            }}
            ref={anchorRef}
            aria-controls={open? 'menu-list-grow':undefined}
            aria-haspopup="true"
            color="inherit"
          />
        }
        label={<IconSettings stroke={1.5} size="1.5rem" color={'white'} />}
        variant="outlined"
        ref={anchorRef}
        aria-controls={open? 'menu-list-grow':undefined}
        aria-haspopup="true"
        onClick={handleToggle}
        color="primary"
      />
      <Popper
        placement="bottom-end"
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        popperOptions={{
          modifiers: [
            {
              name: 'offset',
              options: {
                offset: [0,14]
              }
            }
          ]
        }}
      >
        {({TransitionProps}) => (
          <Transitions in={open} {...TransitionProps}>
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MainCard border={false} elevation={16} content={false} boxShadow shadow={theme.shadows[16]}>
                  {/* {
                    process.env.REACT_APP_ENVIRONMENT!='production'&&(
                      <>
                        <Box sx={{p: 2,paddingBottom: 0}}>
                          <Stack>
                            <Stack direction="row" spacing={0.5} alignItems="center">
                              <Typography variant="h4">Good Morning,</Typography>
                              <Typography component="span" variant="h4" sx={{fontWeight: 400}}>
                                Johne Doe
                              </Typography>
                            </Stack>
                            <Typography variant="subtitle2">Project Admin</Typography>
                          </Stack>
                          <OutlinedInput
                            sx={{width: '100%',pr: 1,pl: 2,my: 2}}
                            id="input-search-profile"
                            value={value}
                            onChange={(e) => setValue(e.target.value)}
                            placeholder="Search profile options"
                            startAdornment={
                              <InputAdornment position="start">
                                <IconSearch stroke={1.5} size="1rem" color={theme.palette.grey[500]} />
                              </InputAdornment>
                            }
                            aria-describedby="search-helper-text"
                            inputProps={{
                              'aria-label': 'weight'
                            }}
                          />
                          <Divider />
                        </Box>
                      </>
                    )
                  } */}
                  {/* <PerfectScrollbar style={{ height: '100%', maxHeight: 'calc(100vh - 250px)', overflowX: 'hidden' }}> */}
                  <Box sx={{p: 2,paddingTop: 0,paddingBottom: 0}}>
                    {/* {
                      process.env.REACT_APP_ENVIRONMENT!='production'&&(
                        <>
                          <Card
                            sx={{
                              bgcolor: theme.palette.primary.light,
                              my: 2
                            }}
                          >
                            <CardContent>
                              <Grid container spacing={3} direction="column">
                                <Grid item>
                                  <Grid item container alignItems="center" justifyContent="space-between">
                                    <Grid item>
                                      <Typography variant="subtitle1">Start DND Mode</Typography>
                                    </Grid>
                                    <Grid item>
                                      <Switch
                                        color="primary"
                                        checked={sdm}
                                        onChange={(e) => setSdm(e.target.checked)}
                                        name="sdm"
                                        size="small"
                                      />
                                    </Grid>
                                  </Grid>
                                </Grid>
                                <Grid item>
                                  <Grid item container alignItems="center" justifyContent="space-between">
                                    <Grid item>
                                      <Typography variant="subtitle1">Allow Notifications</Typography>
                                    </Grid>
                                    <Grid item>
                                      <Switch
                                        checked={notification}
                                        onChange={(e) => setNotification(e.target.checked)}
                                        name="sdm"
                                        size="small"
                                      />
                                    </Grid>
                                  </Grid>
                                </Grid>
                              </Grid>
                            </CardContent>
                          </Card>
                          <Divider />
                        </>
                      )
                    } */}
                    <List
                      component="nav"
                      sx={{
                        width: '100%',
                        maxWidth: 350,
                        minWidth: 300,
                        // backgroundColor: theme.palette.background.paper,
                        borderRadius: '10px',
                        [theme.breakpoints.down('md')]: {
                          minWidth: '100%'
                        },
                        '& .MuiListItemButton-root': {
                          mt: 0.5
                        }
                      }}
                    >
                      {/* {
                        process.env.REACT_APP_ENVIRONMENT!='production'&&(
                          <>
                            <ListItemButton
                              sx={{borderRadius: `${customization.borderRadius}px`}}
                              selected={selectedIndex===0}
                              onClick={(event) => handleListItemClick(event,0,'configuracoes/usuarios/settings')}
                            >
                              <ListItemIcon>
                                <IconSettings stroke={1.5} size="1.3rem" />
                              </ListItemIcon>
                              <ListItemText primary={<Typography variant="body2">Configurações da Conta</Typography>} />
                            </ListItemButton>
                            <ListItemButton
                              sx={{borderRadius: `${customization.borderRadius}px`}}
                              selected={selectedIndex===1}
                              onClick={(event) => handleListItemClick(event,1,'configuracoes/usuarios/profile')}
                            >
                              <ListItemIcon>
                                <IconUser stroke={1.5} size="1.3rem" />
                              </ListItemIcon>
                              <ListItemText
                                primary={
                                  <Grid container spacing={1} justifyContent="space-between">
                                    <Grid item>
                                      <Typography variant="body2">Perfil</Typography>
                                    </Grid>
                                  </Grid>
                                }
                              />
                            </ListItemButton>
                          </>
                        )
                      } */}
                      <ListItemButton
                        sx={{borderRadius: `${customization.borderRadius}px`}}
                        selected={selectedIndex===4}
                        onClick={async () => {
                          try {
                            axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/logout`,{},{
                              headers: {
                                'Content-Type': 'application/json',
                                Authorization: `Bearer ${localStorage.getItem('accessToken')}`
                              }
                            })
                            navigate('/login')
                          } catch {
                            navigate('/login')
                          }
                        }}
                      >
                        <ListItemIcon>
                          <IconLogout stroke={1.5} size="1.3rem" />
                        </ListItemIcon>
                        <ListItemText primary={<Typography variant="body2">Sair</Typography>} />
                      </ListItemButton>
                    </List>
                  </Box>
                  {/* </PerfectScrollbar> */}
                </MainCard>
              </ClickAwayListener>
            </Paper>
          </Transitions>
        )}
      </Popper>
    </>
  );
};

export default ProfileSection;
