import PropTypes from 'prop-types';

// material-ui
import {useTheme} from '@mui/material/styles';
import {Avatar,Box,ButtonBase, useMediaQuery} from '@mui/material';

// project imports
import LogoSection from '../LogoSection';
import SearchSection from './SearchSection';
import NotificationSection from './NotificationSection';

// assets
import {IconMenu2,IconMaximize} from '@tabler/icons';
import {useSelector} from 'react-redux';

// ==============================|| MAIN NAVBAR / HEADER ||============================== //

const Header=({handleLeftDrawerToggle,tableRef}) => {
  const theme=useTheme();
  const leftDrawerOpened=useSelector((state) => state.customization.opened);
  const isTablet=useMediaQuery(theme.breakpoints.down('md'));

  const enterFullscreen=() => {
    const elem=tableRef.current;
    if(elem.requestFullscreen) {
      elem.requestFullscreen();
    } else if(elem.mozRequestFullScreen) { // Firefox
      elem.mozRequestFullScreen();
    } else if(elem.webkitRequestFullscreen) { // Chrome, Safari, and Opera
      elem.webkitRequestFullscreen();
    }
  };

  const exitFullscreen=() => {
    if(document.exitFullscreen) {
      document.exitFullscreen();
    } else if(document.mozCancelFullScreen) { // Firefox
      document.mozCancelFullScreen();
    } else if(document.webkitExitFullscreen) { // Chrome, Safari, and Opera
      document.webkitExitFullscreen();
    }
  };

  const handleFullscreen=() => {
    if(!document.fullscreenElement) {
      enterFullscreen();
    } else {
      exitFullscreen();
    }
  };

  return (
    <>
      {/* logo & toggler button */}
      <Box
        sx={{
          width: !isTablet ? 260 : 80,
          display: 'flex',
          [theme.breakpoints.down('md')]: {
            // width: 'auto',
            bgcolor: 'inherit !important',
          },
          [theme.breakpoints.up('md')]: {
            boxShadow: leftDrawerOpened? '9px 13px 15px -3px rgba(0,0,0,0.1)':'',
          },
          alignItems: 'center',
          bgcolor: leftDrawerOpened? theme.palette.background.default:'inherit',
          p: '16px'
        }}
      >
        <Box component="span" sx={{display: {xs: 'none',md: 'block'},flexGrow: 1}}>
          <LogoSection />
        </Box>
        <ButtonBase sx={{borderRadius: '12px',overflow: 'hidden'}}>
          <Avatar
            variant="rounded"
            sx={{
              ...theme.typography.commonAvatar,
              ...theme.typography.mediumAvatar,
              transition: 'all .2s ease-in-out',
              background: `${theme.palette.reso.orange}`,
              color: 'white',
              '&[aria-controls="menu-list-grow"],&:hover': {
                backgroundColor: '#2196f3',
              }
            }}
            onClick={handleLeftDrawerToggle}
            color="inherit"
          >
            <IconMenu2 stroke={1.5} size="1.3rem" />
          </Avatar>
        </ButtonBase>
      </Box>
      {/* header search */}
      <SearchSection />
      <Box sx={{flexGrow: 1}} />
      <Box sx={{flexGrow: 1}} />

      {/* notification & profile */}
      <Box sx={{display: 'none'}}>
      {/* <Box sx={{display: {xs: 'none',md: 'flex', marginRight: '17px'}}}> */}
        <ButtonBase sx={{borderRadius: '12px'}} onClick={() => handleFullscreen()}>
          <Avatar
            variant="rounded"
            sx={{
              ...theme.typography.commonAvatar,
              ...theme.typography.mediumAvatar,
              transition: 'all .2s ease-in-out',
            }}
            aria-haspopup="true"
            color="inherit"
          >
             <IconMaximize />
          </Avatar>
        </ButtonBase>
      </Box>
      <NotificationSection />
    </>
  );
};

Header.propTypes={
  handleLeftDrawerToggle: PropTypes.func
};

export default Header;
