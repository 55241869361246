import {useSelector} from 'react-redux';

import {ThemeProvider} from '@mui/material/styles';
import {CssBaseline,StyledEngineProvider} from '@mui/material';
import 'react-calendar-timeline/lib/Timeline.css'

// routing
import Routes from 'routes';

// defaultTheme
import themes from 'themes';

// project imports
import NavigationScroll from 'layout/NavigationScroll';
import io from 'socket.io-client';
import {useEffect} from 'react';
import {useConfigMenu} from 'contexts/ConfigMenu';

// ==============================|| APP ||============================== //

const App=() => {
  const menu=useConfigMenu();
  
  useEffect(() => {
    const menuGerado=menu?.response?.config?.nodeJs

    if(menuGerado&&menu.items.length>0) {
      const salas=[]
      salas.push(menu.response.config.urlTratada)
      salas.push(menu.response.config.urlTratada+'_'+menu.user.id)
      if(menu?.response?.config?.asterisk) {
        salas.push(menu.response.config.urlTratada+'_asterisk')
      }

      const linkNodeJs = `${process.env.REACT_APP_SOCKET_URL}` || `${window.location.protocol}//${menu.response.config.nodeJs}`;
      const socket=io(linkNodeJs,{
        transports: ['websocket','polling','flashsocket'],
        reconnection: false,
        query: {
          userId: JSON.stringify(salas)
        }
      });

      socket.on('disconnect', function() {
        socket.io.reconnect();
        console.log('Desconectado');
    })

      socket.on('connect', () => {
        console.log('Conectado');
      });

      socket.on(menu.response.config.urlTratada,function(data) {
        console.log(data);
      });

      socket.on(`${menu.response.config.urlTratada}_${menu.user.id}`,function(data) {
        console.log(data);
      });

      if(menu?.response?.config?.asterisk) {
        socket.on(`${menu.response.config.urlTratada}_asterisk`,function(data) {
          console.log(data);
        });
      }

      socket.on('notificacoesGerais',function(data) {
        console.log(data);
      });

    }
  },[menu]);

  const customization=useSelector((state) => state.customization);
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={themes(customization)}>
        <CssBaseline />
        <NavigationScroll>
          <Routes />
        </NavigationScroll>
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

export default App;
