import React from 'react';
import Divider from "@mui/material/Divider";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import Button from "@mui/material/Button";
import {Avatar,ButtonBase,CardActions,CardHeader,IconButton,List,ListItemAvatar,ListItemButton,ListItemText,Popover,Stack,Typography} from "@mui/material";
import Div from 'ui-component/layouts/components/Div';
import {IconEdit,IconMessage,IconStar} from '@tabler/icons';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import {Box, useTheme} from '@mui/system';

const MessagesDropdown=() => {
    const [anchorEl,setAnchorEl]=React.useState(null);
    const theme = useTheme();
    const isOpen=Boolean(anchorEl);

    const handleClick=React.useCallback((event) => {
        setAnchorEl(event.currentTarget);
    },[]);

    const handleClose=React.useCallback(() => {
        setAnchorEl(null);
    },[]);

    return (
        <>
            <Box
                sx={{
                    ml: 2,
                }}
            >
                <ButtonBase sx={{borderRadius: '12px', boxShadow: '0px 0px 4px 0px rgba(0, 0, 0, 0.75)'}}>
                    <Avatar
                        variant="rounded"
                        sx={{
                            ...theme.typography.commonAvatar,
                            ...theme.typography.mediumAvatar,
                            transition: 'all .2s ease-in-out',
                        }}
                        ref={anchorEl}
                        aria-controls={isOpen? 'menu-list-grow':undefined}
                        aria-haspopup="true"
                        onClick={handleClick}
                        color="inherit"
                    >
                        <IconMessage />
                    </Avatar>
                </ButtonBase>
                <Popover
                    open={isOpen}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                    sx={{
                        mt: 2,
                        mr: 6
                    }}
                >
                    <Div sx={{width: 360,maxWidth: '100%'}}>
                        <CardHeader
                            title={"Mensagens"}
                            subheader={"1 nova mensagem"}
                            action={
                                <Stack direction="row" alignItems="center" sx={{mr: 1}}>
                                    <IconButton aria-label="compose">
                                        <IconEdit fontSize={"small"} />
                                    </IconButton>
                                    <IconButton aria-label="starred">
                                        <IconStar fontSize={"small"} />
                                    </IconButton>
                                </Stack>
                            }
                            sx={{
                                '& .MuiCardHeader-action': {
                                    alignSelf: 'center',
                                }
                            }}
                        />
                        <Div sx={{m: 2,mt: 0}}>
                            {/* <InputTextField  placeholder="Search messages" fullWidth /> */}
                        </Div>
                        <List disablePadding>
                            <ListItemButton component={"li"} disableRipple>
                                <ListItemAvatar>
                                    <Avatar src='' />
                                </ListItemAvatar>
                                <ListItemText primary={<Typography variant={"h6"} mb={.25}>Ruan</Typography>}
                                    secondary={<Typography noWrap color={"text.secondary"}>Olá! Tudo bem?</Typography>}
                                />
                                <IconButton elevation={1} edge={"end"} size={"small"}><MoreHorizIcon /></IconButton>
                            </ListItemButton>
                        </List>
                        <Divider />
                        <CardActions sx={{justifyContent: 'center'}}>
                            <Button
                                sx={{
                                    textTransform: "none",
                                    fontWeight: 'normal',
                                    '&:hover': {bgcolor: 'transparent'}
                                }}
                                size={"small"} variant="text" endIcon={<ArrowForwardIcon />} disableRipple>
                                Ver todas
                            </Button>
                        </CardActions>
                    </Div>
                </Popover>
            </Box>
        </>
    );
};

export default MessagesDropdown;
