import {Box,useMediaQuery,useTheme} from '@mui/material';

import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';
import 'dayjs/locale/pt';

import {DatePicker,LocalizationProvider} from '@mui/x-date-pickers';
import {GlobalStyles} from '@mui/material';
import Div from 'ui-component/layouts/components/Div';
import {useDateFilterConfig} from 'contexts/DateFilterConfig';
import dayjs from 'dayjs';

const locale='pt';

// ==============================|| SEARCH INPUT - MOBILE||============================== //


// ==============================|| SEARCH INPUT ||============================== //

const SearchSection=() => {
  const theme=useTheme();
  const isTablet=useMediaQuery(theme.breakpoints.down('md'));
  const {DateFilterConfig,setDateFilterConfig}=useDateFilterConfig();
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={locale}>
      <GlobalStyles
        styles={{
          '[class*="MuiPickersCalendarHeader-labelContainer"]': {
            color: 'black',
          },
          '[class*="MuiPickersDay-root"]': {
            color: 'black !important',
            '& .Mui-selected': {
              color: 'white !important',
              backgroundColor: `${theme.primaryPinePr} !important`
            },
            '&.Mui-selected': {
              color: 'white !important',
              backgroundColor: `${theme.primaryPinePr} !important`
            },
          },
          '[class*="MuiPickersYear-yearButton"]': {
            color: 'black !important',
          },
          '[class*="MuiInputBase-inputAdornedEnd"]': {
            color: 'black !important',
            fontSize: isTablet&&'0.8rem !important',
          },
        }}
      />
      <Box sx={{display: {xs: 'flex',md: 'none'},marginLeft: !isTablet&&'35px'}}>
        <DatePicker value={DateFilterConfig.startDate} label='Periodo Inicial' onChange={(e) => {
          // a diferença das datas não pode ser maior que 6 meses
          if(dayjs(e).diff(DateFilterConfig.endDate,'month')<-6) {
            setDateFilterConfig((prev) => ({...prev,startDate: DateFilterConfig.endDate.add(-6,'month')}));
            localStorage.setItem('startDate',JSON.stringify(DateFilterConfig.endDate.add(-6,'month')));
          } else {
            setDateFilterConfig((prev) => ({...prev,startDate: e}));
            localStorage.setItem('startDate',JSON.stringify(e));
          }
        }} />
        <Div>-</Div>
        <DatePicker value={DateFilterConfig.endDate} label='Periodo Final' onChange={(e) => {
          // a diferença das datas não pode ser maior que 6 meses
          if(dayjs(e).diff(DateFilterConfig.startDate,'month')>6) {
            setDateFilterConfig((prev) => ({...prev,endDate: DateFilterConfig.startDate.add(6,'month')}));
            localStorage.setItem('endDate',JSON.stringify(DateFilterConfig.startDate.add(6,'month')));
          } else {
            setDateFilterConfig((prev) => ({...prev,endDate: e}));
            localStorage.setItem('endDate',JSON.stringify(e));
          }
        }} />
      </Box>
      <Box sx={{display: {xs: 'none',md: 'flex'},marginLeft: !isTablet&&'35px'}}>
        <DatePicker value={DateFilterConfig.startDate} label='Periodo Inicial' onChange={(e) => {
          if(dayjs(e).diff(DateFilterConfig.endDate,'month')<-6) {
            setDateFilterConfig((prev) => ({...prev,startDate: DateFilterConfig.endDate.add(-6,'month')}));
            localStorage.setItem('startDate',JSON.stringify(DateFilterConfig.endDate.add(-6,'month')));
          } else {
            setDateFilterConfig((prev) => ({...prev,startDate: e}));
            localStorage.setItem('startDate',JSON.stringify(e));
          }
        }} />
        <Div>-</Div>
        <DatePicker value={DateFilterConfig.endDate} label='Periodo Final' onChange={(e) => {
          if(dayjs(e).diff(DateFilterConfig.startDate,'month')>6) {
            setDateFilterConfig((prev) => ({...prev,endDate: DateFilterConfig.startDate.add(6,'month')}));
            localStorage.setItem('endDate',JSON.stringify(DateFilterConfig.startDate.add(6,'month')));
          } else {
            setDateFilterConfig((prev) => ({...prev,endDate: e}));
            localStorage.setItem('endDate',JSON.stringify(e));
          }
        }} />
      </Box>
    </LocalizationProvider>
  );
};

export default SearchSection;
