// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ql-container.ql-snow {
    border: 0px solid #354868 !important;
    border-inline: 1px solid #354868 !important;
    border-top: 1px solid #354868 !important;
    border-bottom: 0px solid #354868 !important;
    min-height: 230px;
}

.quill-feedback .ql-toolbar.ql-snow {
    border: 0px solid #354868 !important;
    border-inline: 1px solid #354868 !important;
    border-top: 0px solid #354868 !important;
    border-bottom: 1px solid #354868 !important;
    display: block;
    justify-content: end;
    padding-top: 10px;
    background: rgb(25, 36, 59) !important;
}

.quill {
    display: flex;
    flex-direction: column-reverse;
}

.ql-snow .ql-stroke {
    stroke: white !important;
}

.ql-snow .ql-fill {
    fill: white !important;
}

.ql-toolbar.ql-snow .ql-formats {
    margin-right: 0 !important;
}

.ql-editor.ql-blank::before {
    color: #697586 !important;
}

.ql-snow.ql-toolbar button.ql-active .ql-stroke {
    stroke: #07c  !important;
}	

.quill-error .ql-editor.ql-blank::before {
    color: #f44336 !important;
  }

.ql-bubble {
   background: rgba(25, 36, 59, 0.5) !important;
   padding-bottom: 20px;
}

.ql-bubble .ql-editor {
    margin-bottom: -30px;
}

.ql-editor p img {
    max-width: 100%;
}

.ql-picker-label {
    color: white !important;
}`, "",{"version":3,"sources":["webpack://./src/layout/MainLayout/Feedback/quill.css"],"names":[],"mappings":"AAAA;IACI,oCAAoC;IACpC,2CAA2C;IAC3C,wCAAwC;IACxC,2CAA2C;IAC3C,iBAAiB;AACrB;;AAEA;IACI,oCAAoC;IACpC,2CAA2C;IAC3C,wCAAwC;IACxC,2CAA2C;IAC3C,cAAc;IACd,oBAAoB;IACpB,iBAAiB;IACjB,sCAAsC;AAC1C;;AAEA;IACI,aAAa;IACb,8BAA8B;AAClC;;AAEA;IACI,wBAAwB;AAC5B;;AAEA;IACI,sBAAsB;AAC1B;;AAEA;IACI,0BAA0B;AAC9B;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;IACI,wBAAwB;AAC5B;;AAEA;IACI,yBAAyB;EAC3B;;AAEF;GACG,4CAA4C;GAC5C,oBAAoB;AACvB;;AAEA;IACI,oBAAoB;AACxB;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,uBAAuB;AAC3B","sourcesContent":[".ql-container.ql-snow {\n    border: 0px solid #354868 !important;\n    border-inline: 1px solid #354868 !important;\n    border-top: 1px solid #354868 !important;\n    border-bottom: 0px solid #354868 !important;\n    min-height: 230px;\n}\n\n.quill-feedback .ql-toolbar.ql-snow {\n    border: 0px solid #354868 !important;\n    border-inline: 1px solid #354868 !important;\n    border-top: 0px solid #354868 !important;\n    border-bottom: 1px solid #354868 !important;\n    display: block;\n    justify-content: end;\n    padding-top: 10px;\n    background: rgb(25, 36, 59) !important;\n}\n\n.quill {\n    display: flex;\n    flex-direction: column-reverse;\n}\n\n.ql-snow .ql-stroke {\n    stroke: white !important;\n}\n\n.ql-snow .ql-fill {\n    fill: white !important;\n}\n\n.ql-toolbar.ql-snow .ql-formats {\n    margin-right: 0 !important;\n}\n\n.ql-editor.ql-blank::before {\n    color: #697586 !important;\n}\n\n.ql-snow.ql-toolbar button.ql-active .ql-stroke {\n    stroke: #07c  !important;\n}\t\n\n.quill-error .ql-editor.ql-blank::before {\n    color: #f44336 !important;\n  }\n\n.ql-bubble {\n   background: rgba(25, 36, 59, 0.5) !important;\n   padding-bottom: 20px;\n}\n\n.ql-bubble .ql-editor {\n    margin-bottom: -30px;\n}\n\n.ql-editor p img {\n    max-width: 100%;\n}\n\n.ql-picker-label {\n    color: white !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
