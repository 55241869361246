import {useRoutes} from 'react-router-dom';

// routes
// import MainRoutes from './MainRoutes';
import MainRoutesDash from './MainRoutesDash';
import Loadable from 'ui-component/Loadable';
import {lazy} from 'react';

import LoginRoutes from './LoginRoutes';
import {useConfigMenu} from 'contexts/ConfigMenu';
import MainLayout from 'layout/MainLayout';
import renderImports from './imports';
const Listar=Loadable(lazy(() => import('../layout/ListarLayout')));
const DashboardDefault=Loadable(lazy(() => import('views/dashboard/Default')));
const ControleCarros=Loadable(lazy(() => import('views/pages/outros/painel-de-controle/Painel')));
const ListarFuncionarios=Loadable(lazy(() => import('views/pages/outros/funcionarios/Funcionarios')));
const Mapa=Loadable(lazy(() => import('views/pages/outros/mapa/Mapa')));
const ListarCargoSalario=Loadable(lazy(() => import('views/pages/outros/cargo-salario/CargoSalario')));

const MainRoutes={
  path: '/',
  element: <MainLayout />,
  children: [{
    path: '/dashboard',
    element: <DashboardDefault />
  }]
}

const MainRoutesManual={
  path: '/',
  element: <MainLayout />,
  children: [
    {
      path: '/controle-de-carros',
      element: <ControleCarros />
    },
    {
      path: '/mapa',
      element: <Mapa />
    },
    {
      path: '/',
      children: [
        {
          path: 'funcionarios/listar',
          element: <ListarFuncionarios />,
        },
        {
          path: 'funcionarios/cadastrar',
          element: renderImports('funcionarios','cadastrar')
        },
        {
          path: 'funcionarios/editar/:id',
          element: renderImports('funcionarios','editar')
        }
      ]
    },
    {
      path: '/',
      children: [
        {
          path: 'cargo-salario/listar',
          element: <ListarCargoSalario />,
        },
        {
          path: 'cargo-salario/cadastrar',
          element: renderImports('cargo-salario','cadastrar')
        },
        {
          path: 'cargo-salario/editar/:id',
          element: renderImports('cargo-salario','editar')
        }
      ]
    }
  ]
}

const ElementRender=(chave,item,itemChildren) => {
  let columns=[];
  if(chave==='listar') {
    if(itemChildren?.telaListar) {
      columns=itemChildren?.telaListar.map((item) => {
        if(item.value.includes('.')) {
          const splited=item.value.split('.');
          return {
            ...item,
            value: splited[splited.length-1],
            nested: item.value,
            isNested: true,
          }
        } else {
          return item;
        }
      });
    }
  }
  switch(chave) {
    case 'listar':
      return <Listar key={`/${item.id}/${itemChildren.id}`} visualizarListar={false} isNewRequest columns={[...columns,{value: 'action',label: 'Opc.',align: 'center'}]} name={itemChildren.title} rotaBaseNavigate={`${item.id}/${itemChildren.id}`} rotaBase={`${item.id}/${itemChildren.id}`} />
    case 'cadastrar':
      return renderImports(itemChildren.id,'cadastrar')
    case 'editar':
      return renderImports(itemChildren.id,'editar')
  }
}

export default function ThemeRoutes() {
  const configMenu=useConfigMenu();

  if(MainRoutes.children.length===1) {
    configMenu.items.map((item) => {
      item.children.map((itemChildren) => {
        if(itemChildren.botoesMenu) {
          const keys=Object.keys(itemChildren.botoesMenu);
          const objData={
            path: `/${item.id}/${itemChildren.id}`,
            children: []
          }
          keys.map((chave) => {
            const objChave={
              path: chave==='editar'? `editar/:id`:chave,
              element: ElementRender(chave,item,itemChildren)
            }
            objData.children.push(objChave);
          })
          MainRoutes.children.push(objData)
        } else {
          MainRoutes.children.push({
            path: `/${item.id}/${itemChildren.id}`,
            element: renderImports(itemChildren.id)
          })
        }
      })
    });
  }

  const routes=[]

  if(process.env.REACT_APP_ENVIRONMENT!='production') {
    routes.push(MainRoutesManual);
  }

  return useRoutes([LoginRoutes,MainRoutes,MainRoutesDash,routes]);
}
