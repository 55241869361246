import {useSelector} from 'react-redux';

import {ThemeProvider} from '@mui/material/styles';
import {CssBaseline,StyledEngineProvider} from '@mui/material';
import 'react-calendar-timeline/lib/Timeline.css'

// routing
import Routes from 'routes';

// defaultTheme
import themes from 'themes';

// project imports
import NavigationScroll from 'layout/NavigationScroll';

// ==============================|| APP ||============================== //

const App=() => {
  const customization=useSelector((state) => state.customization);
  return (
    <StyledEngineProvider injectFirst>
        <ThemeProvider theme={themes(customization)}>
          <CssBaseline />
          <NavigationScroll>
            <Routes />
          </NavigationScroll>
        </ThemeProvider>
    </StyledEngineProvider>
  );
};

export default App;
